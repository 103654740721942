import React, { useEffect, useState } from 'react'
import "./Gallery.css"

// Mantine
import { Tabs } from '@mantine/core'


// Images
import Image1 from "../../Assets/Images/Image1.webp"
import Image2 from "../../Assets/Images/Image2.webp"
import Image3 from "../../Assets/Images/Image3.webp"
import Image4 from "../../Assets/Images/Image4.webp"
import Image5 from "../../Assets/Images/Image5.webp"
import Image6 from "../../Assets/Images/Image6.webp"
import Image7 from "../../Assets/Images/Image7.webp"
import Image8 from "../../Assets/Images/Image8.webp"
import Image9 from "../../Assets/Images/Image9.webp"
import Image10 from "../../Assets/Images/Image10.webp"
import Image11 from "../../Assets/Images/Image11.webp"
import Image12 from "../../Assets/Images/Image12.webp"
import Image13 from "../../Assets/Images/Image13.webp"
import Image14 from "../../Assets/Images/Image14.webp"
import Image15 from "../../Assets/Images/Image15.webp"
import Image16 from "../../Assets/Images/Image16.webp"
import Image17 from "../../Assets/Images/Image17.webp"
import Image18 from "../../Assets/Images/Image18.webp"
import Image19 from "../../Assets/Images/Image19.webp"
import Image20 from "../../Assets/Images/Image20.webp"
import Image21 from "../../Assets/Images/Image21.webp"
import Image22 from "../../Assets/Images/Image22.webp"
import Image23 from "../../Assets/Images/Image23.webp"
import Image24 from "../../Assets/Images/Image24.webp"
import Image25 from "../../Assets/Images/Image25.webp"
import Image26 from "../../Assets/Images/Image26.webp"
import Image27 from "../../Assets/Images/Image28.webp"
import Image28 from "../../Assets/Images/Image29.webp"


const Gallery = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const all = [
    Image1,
    Image2,
    Image3,
    Image4,
    Image5,
    Image6,
    Image7,
    Image8,
    Image9,
    Image10,
    Image11,
    Image12,
    Image13,
    Image14,
    Image15,
    Image16,
    Image17,
    Image18,
    Image19,
    Image20,
    Image21,
    Image22,
    Image23,
    Image24,
    Image25,
    Image26,
    Image27,
    Image28,
  ]

  const [galleryImages, setGalleryImages] = useState(all)
  const [galleryId, setGalleryId] = useState(1)


  const Rooms = [
    Image2,
    Image3,
    Image5,
    Image7,
    Image10,
    Image28,    
  ]

  const Views = [
    Image12,
    Image13,
    Image14,
    Image21,
    Image23,
  ]

  const PlayArea = [

    Image8,
    Image15,
    Image16,
    Image17,
    Image18,
    Image19,
    Image22,
    Image27,
  ]

  const FireCamp = [

    Image26,
    Image25,
    Image24,
    Image11,
    Image8,
    Image16,
  ]


  useEffect(() => {
    if (galleryId === 1) {
      setGalleryImages(all)
    }
    else if (galleryId === 2) {
      setGalleryImages(Rooms)
    }
    else if (galleryId === 3) {
      setGalleryImages(Views)
    }
    else if (galleryId === 4) {
      setGalleryImages(PlayArea)
    }
    else if (galleryId === 5) {
      setGalleryImages(FireCamp)
    }
  }, [galleryId])

  return (
    <div>
      <div className='gallery-page'>
        <div className='gallery-header-title-image'>
          <h1>
            Gallery
          </h1>
          <p>
            Valley View
          </p>
        </div>


        <div className='gallery-container'>
          <div className='gallery-container-tabs'>
            <Tabs variant="pills" color={'#41566B'} className='gallery-container-tab' defaultValue="all">
              <Tabs.List className='gallery-container-tab-list'>
                <Tabs.Tab onClick={() => setGalleryId(1)} className={` ${galleryId === 1 ? 'gallery-container-tabs-list-item' : 'gallery-container-tabs-list-1st'}`} value="all">All</Tabs.Tab>
                <Tabs.Tab onClick={() => setGalleryId(2)} className='gallery-container-tabs-list-item' value="rooms">  Rooms  </Tabs.Tab>
                <Tabs.Tab onClick={() => setGalleryId(3)} className='gallery-container-tabs-list-item' value="views"> Views   </Tabs.Tab>
                <Tabs.Tab onClick={() => setGalleryId(4)} className='gallery-container-tabs-list-item' value="playarea"> Play area   </Tabs.Tab>
                <Tabs.Tab onClick={() => setGalleryId(5)} className='gallery-container-tabs-list-item' value="firecamp"> Fire Camp   </Tabs.Tab>
              </Tabs.List>
            </Tabs>
          </div>
          <div className='gallery-container-gallery'>
            {
              galleryImages.map((value, index) => {
                return (
                  <div key={index} className='gallery-container-gallery-image'>
                    <img src={value} alt='Vallet-View' />
                    <div className='gallery-container-gallery-image-animation'>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Gallery
