import React from 'react'
import "./App.css"

// Pages
import Router from './Router'

const App = () => {
    return (
        <div>
            <Router />
        </div>
    )
}

export default App
