import React from 'react'
import "./NavBar.css"

// RRD
import { Link } from "react-router-dom"


const NavBar = ({ opened, toggle }) => {

    const handleClick = () => {
        toggle()
        window.scrollTo(0, 0)
    }

    return (
        <div>
            <div className='navbar-page'>
                <div className={`navbar-container ${opened ? 'open-nav-bar' : ''}`}>
                    <ul>
                        <li>
                            <Link to={'/'} onClick={handleClick} className="nav-link">
                                Home
                            </Link>
                        </li>
                        <li>
                            <Link onClick={handleClick} to={'/about'} className="nav-link">
                                About
                            </Link>
                        </li>
                        <li>
                            <Link onClick={handleClick} to={'/rooms'} className="nav-link">
                                Rooms
                            </Link>
                        </li>
                        <li>
                            <Link onClick={handleClick} to={'/gallery'} className="nav-link">
                                Gallery
                            </Link>
                        </li>
                        <li>
                            <Link onClick={handleClick} to={'/contact'} className="nav-link">
                                Contact
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default NavBar
