import React, { useEffect, useState } from 'react'
import "./Rooms.css"


// Images
import Room1 from "../../Assets/Images/Image2.webp"
import Room2 from "../../Assets/Images/Image3.webp"
import Room3 from "../../Assets/Images/Image5.webp"
import Room4 from "../../Assets/Images/Image7.webp"
import Room5 from "../../Assets/Images/Image10.webp"
import Room6 from "../../Assets/Images/Image4.webp"
import Room7 from "../../Assets/Images/Image6.webp"

// ---IconImages
import Bath from "../../Assets/RoomsIcons/Bath.svg"
import Bed from "../../Assets/RoomsIcons/Bed.svg"
import Wifi from "../../Assets/RoomsIcons/Wifi.svg"
import Teapot from "../../Assets/RoomsIcons/Teapot.svg"
import Fire from "../../Assets/RoomsIcons/Fire.svg"
import Food from "../../Assets/RoomsIcons/Dish.svg"
import Genset from "../../Assets/RoomsIcons/Genset.svg"


// Mantine
import { Center, List, ThemeIcon } from '@mantine/core'


// Tabler ICon
import { Star } from 'tabler-icons-react'
import { useMediaQuery } from '@mantine/hooks'


// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';


// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';




const Rooms = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const RoomsImages = [
    Room1, Room2, Room3, Room4, Room5, Room6, Room7
  ]

  const [roomIamges, setRoomImages] = useState(Room1)
  const [roomIndex, setRoomIndex] = useState(0)


  useEffect(() => {
    if (roomIndex === 0) {
      setRoomImages(Room1)
    }
    else if (roomIndex === 1) {
      setRoomImages(Room2)
    }
    else if (roomIndex === 2) {
      setRoomImages(Room3)
    }
    else if (roomIndex === 3) {
      setRoomImages(Room4)
    }
    else if (roomIndex === 4) {
      setRoomImages(Room5)
    }
    else if (roomIndex === 5) {
      setRoomImages(Room6)
    }
    else if (roomIndex === 6) {
      setRoomImages(Room7)
    }
  }, [roomIndex])

  // Media Query
  const Media500 = useMediaQuery('(max-width:500px)')
  return (
    <div>
      <div className='room-page'>
        {/* Header */}
        <div className='room-header-title-image'>
          <h1>
            Hotel Rooms
          </h1>
          <p>
            Valley View
          </p>
        </div>
        {/* Header */}


        {/* Rooms Image */}

        <div className='room-container'>
          <div className='room-container-images'>
            <div className='room-container-image'>
              <Swiper
                className='room-container-image-swiper'
                loop={true}
                navigation={true}
                autoplay={
                  {
                    delay: 3500,
                    disableOnInteraction: false
                  }
                }
                modules={[Autoplay, Pagination, Navigation]}
              >
                {
                  RoomsImages.map((values, index) => {
                    return (
                      <SwiperSlide
                        key={index}
                        className='room-container-image-swiper-slide'
                      >
                        <img src={values} alt='Valley-View' />
                      </SwiperSlide>
                    )
                  })
                }

              </Swiper>
            </div>
          </div>
        </div>
        <div className='rooms-icons-container'>
          <div className='rooms-icons-container-svg'>
            <img src={Bed} alt='rooms-icon' />
          </div>
          <div className='rooms-icons-container-svg'>
            <img src={Bath} alt='rooms-icon' />
          </div>
          <div className='rooms-icons-container-svg'>
            <img src={Wifi} alt='rooms-icon' />
          </div>
          <div className='rooms-icons-container-svg'>
            <img src={Teapot} alt='rooms-icon' />
          </div>
          <div className='rooms-icons-container-svg'>
            <img src={Fire} alt='rooms-icon' />
          </div>
          <div className='rooms-icons-container-svg'>
            <img src={Food} alt='rooms-icon' />
          </div>
          <div className='rooms-icons-container-svg'>
            <img src={Genset} alt='rooms-icon' />
          </div>
        </div>

        <div className='room-amenities-container'>
          <div className='room-amenities-container-items'>
            <List
              spacing="xs"
              size="lg"
              center
              icon={
                <ThemeIcon color="teal" size={24} radius="xl">
                  <Star size="1rem" />
                </ThemeIcon>
              }
            >
              <List.Item>
                2 Bedrooms in Concrete Building
              </List.Item>
              <List.Item>
                1 Bedroom in Sheet Building
              </List.Item>
              <List.Item>
                3 Attached Bathrooms
              </List.Item>
            </List>
          </div>
          <div className='room-amenities-container-items'>
            <List
              spacing="xs"
              size="lg"
              center
              icon={
                <ThemeIcon color="teal" size={24} radius="xl">
                  <Star size="1rem" />
                </ThemeIcon>
              }
            >
              <List.Item>
                Wifi
              </List.Item>
              <List.Item>
                Power backup
              </List.Item>
              <List.Item>
                Road side parking
              </List.Item>
            </List>
          </div>
          <div className='room-amenities-container-items'>
            <List
              spacing="xs"
              size="lg"
              center
              icon={
                <ThemeIcon color="teal" size={24} radius="xl">
                  <Star size="1rem" />
                </ThemeIcon>
              }
            >
              <List.Item>
                Home made food
              </List.Item>
              <List.Item>
                Firecamp
              </List.Item>
              <List.Item>
                Hot Water and Kettle
              </List.Item>
            </List>
          </div>
        </div>

        <div className='room-check-in-check-out'>
          <div className='room-check-in'>
            <div className='room-check-in-title'>
              <h1>
                Check In
              </h1>
            </div>
            <div className='room-check-in-content'>
              <List
              withPadding
              listStyleType='none'
              spacing="md"
              size="xl"
              >
                <List.Item>
                  Check-in from 12.00 PM – anytime
                </List.Item>
              </List>
            </div>
          </div>
          <div className='room-check-out'>
            <div className='room-check-out-title'>
              <h1>
                Check out
              </h1>
            </div>
            <div className='room-check-out-content'>
              <List
              listStyleType='none'
              spacing="md"
              size="xl"
              center
              >
                <List.Item>
                  Check-out before 11.00 AM
                </List.Item>
              </List>
            </div>
          </div>
        </div>
        {/* Rooms Image End */}
      </div>
    </div>
  )
}

export default Rooms
