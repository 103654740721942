import React, { useRef, useState, useEffect } from 'react'
import "./Contact.css"


// Mantine Core
import { Button, Card, Center, Input, Textarea, ThemeIcon } from '@mantine/core'
import { showNotification } from "@mantine/notifications"


// Tabler Icon
import { Mail, MapPin, PhoneCall, Check, X } from 'tabler-icons-react'

// Email Js
import emailjs from '@emailjs/browser';

const Contact = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // Form Data
  const [formData, setFromData] = useState({
    name: '',
    email: "",
    number: '',
    subject: '',
    message: ""
  })

  // Error Handle 
  const [error, setError] = useState(0)


  // useReff
  const formRef = useRef(null)



  const handleSubmit = (e) => {
    e.preventDefault()
    if (formData.name.length <= 0) {
      setError(1)
    }
    else if (formData.email.length <= 0) {
      setError(2)
    }
    else if (formData.number.length <= 0) {
      setError(3)
    }
    else {
      setError(0)
      emailjs.sendForm('service_xnmbwtt', 'template_yauox3q', formRef.current, '4EmzORIDQdWNpQqMb')
        .then((result) => {
          showNotification({
            message: "Mail Send Successfully",
            icon: (
              <ThemeIcon variant="light" radius="xl" size="50px" color="#79d27e">
                <Check color="Green" size={30} />
              </ThemeIcon>
            ),
            autoClose: 2000,
            style: { padding: "1rem", fontSize: "205px" },
          });
          setFromData({
            name: '',
            email: "",
            number: '',
            subject: '',
            message: ""
          })
        })
        .catch((error) => {
          showNotification({
            message: "Error Occured",
            icon: (
              <ThemeIcon variant="light" radius="xl" size="50px" color="red">
                <X color="red" size={30} />
              </ThemeIcon>
            ),
            autoClose: 2000,
            style: { padding: "1rem", fontSize: "205px" },
          });
        })

    }
  }

  useEffect(() => {
    if (formData.name !== null) {
      setError(0)
    }
    else if (formData.email !== null) {
      setError(0)
    }
    else if (formData.number !== null) {
      setError(0)
    }
  }, [formData.name,formData.email,formData.number])

  return (
    <div>
      <div className='contact-page'>
        <div className='contact-header-title-image'>
          <h1>
            Contact us
          </h1>
          <p>
            Valley View
          </p>
        </div>



        <div className='contact-container'>
          <div className='contact-container-title'>
            <Center>
              <h1>
                How to 
                <span className='about-content-para-title-primary-color'> find us </span>
              </h1>
            </Center>
          </div>


          <div className='contact-container-card'>
            <Card className='contact-container-card-mantine-card' shadow="sm" padding="lg" radius="md" withBorder>
              <Center>
                <MapPin className='contact-container-card-mantine-card-icon' size={70} strokeWidth={1} />
              </Center>
              <Center>
                <h1>
                  Location
                </h1>
              </Center>
              <Center>
                <p>
                  Karumbarai road,<br />
                  Near mother Theresa University,<br />
                  Attuvampatty,<br />
                  KodaiKanal.
                </p>
              </Center>
            </Card>
            <Card className='contact-container-card-mantine-card' shadow="sm" padding="lg" radius="md" withBorder>
              <Center>
                <PhoneCall className='contact-container-card-mantine-card-icon' size={70} strokeWidth={1} />
              </Center>
              <Center>
                <h1>
                  Phone us
                </h1>
              </Center>
              <Center>
                <p>
                  +91 98425 05161
                </p>
              </Center>
            </Card>
            <Card className='contact-container-card-mantine-card' shadow="sm" padding="lg" radius="md" withBorder>
              <Center>
                <Mail className='contact-container-card-mantine-card-icon' size={70} strokeWidth={1} />
              </Center>
              <Center>
                <h1>
                  Email us
                </h1>
              </Center>
              <Center>
                <p>
                contact@valleyviewstay.com
                </p>
              </Center>
            </Card>
          </div>



          {/* Map */}

          <div className='contact-container-map'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3925.802062123568!2d77.46988177503492!3d10.277526689842487!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTDCsDE2JzM5LjEiTiA3N8KwMjgnMjAuOCJF!5e0!3m2!1sen!2sin!4v1692709313035!5m2!1sen!2sin"
              allowfullscreen=""
              title='Map'
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
          {/* Map End */}



          {/* Form */}

          <div className='contact-form'>
            <div className='contact-form-title'>
              <Center>
                <h1>
                  Have any questions?
                </h1>
              </Center>
              <Center>
                <p>
                  Subscribe to our newsletters, call us to book a meetup or send us emails to request for service consultation.
                </p>
              </Center>
            </div>

            <form ref={formRef} className='contact-form-container' method='POST'>
              <div className='contact-form-container-inputs-1'>
                <Input.Wrapper
                  withAsterisk
                  error={`${error === 1 ? 'Name is Compulsory' : ''}`}
                  className='contact-form-container-inputs-1-input' label="Name">
                  <Input
                    placeholder='Your Name'
                    name='name'
                    value={formData.name}
                    error={`${error === 1 ? 'Name is Compulsory' : ''}`}
                    onChange={(e) => setFromData({ ...formData, name: e.target.value })}
                  />
                </Input.Wrapper>
                <Input.Wrapper
                  error={`${error === 2 ? 'Email is Compulsory' : ''}`}
                  withAsterisk className='contact-form-container-inputs-1-input' label="Email">
                  <Input
                    placeholder='Your Email'
                    name='email'
                    error={`${error === 2 ? 'Name is Compulsory' : ''}`}
                    value={formData.email}
                    onChange={(e) => setFromData({ ...formData, email: e.target.value })} />
                </Input.Wrapper>
              </div>
              <div className='contact-form-container-inputs-1'>
                <Input.Wrapper
                  error={`${error === 3 ? 'Phone Number is Compulsory' : ''}`}
                  withAsterisk className='contact-form-container-inputs-1-input' label="Phone Number">
                  <Input
                    error={`${error === 3 ? 'Phone Number is Compulsory' : ''}`}
                    placeholder='Your Phone Number'
                    name='number'
                    value={formData.number}
                    onChange={(e) => setFromData({ ...formData, number: e.target.value })}
                  />
                </Input.Wrapper>
                <Input.Wrapper
                  className='contact-form-container-inputs-1-input' label="Subject">
                  <Input
                    name='subject'
                    placeholder='Subject'
                    value={formData.subject}
                    onChange={(e) => setFromData({ ...formData, subject: e.target.value })}
                  />
                </Input.Wrapper>
              </div>
              <div className='contact-form-container-inputs-text-area'>
                <Textarea
                  placeholder="Your comment"
                  label="Your comment"
                  name='message'
                  minRows={5}
                  value={formData.message}
                  onChange={(e) => setFromData({ ...formData, message: e.target.value })}
                />
              </div>
              <Button onClick={handleSubmit} className="contact-form-container-btn">
                Post Comment
              </Button>
            </form>
          </div>

          {/* Form End */}
        </div>
      </div>
    </div>
  )
}

export default Contact
