import React from 'react'
import "./Footer.css"
import { Link } from "react-router-dom"

// Images
import Logo from "../../Assets/Logo.png"


// Mantine 
import { Card, Center, Input, Button } from "@mantine/core"

// Tabler ICons
import { BrandFacebook, BrandInstagram, PhoneCall } from 'tabler-icons-react'

const Footer = () => {
  return (
    <div>
      <div className='footer-page'>
        <div className='footer-container'>
          <div className='footer-container-logo'>
            <Link to="/" onClick={() => window.scrollTo(0, 0)}>
              <div className='footer-container-logo-image'>
                <img src={Logo} alt='ValleyView-Logo' />
              </div>
            </Link>
          </div>
          <div className='footer-container-flex'>
            <Card className='footer-container-card'>
              <Center>
                <h1>
                  About Us
                </h1>
              </Center>
              <Center>
                <ul>
                  <p>Valley View Bungalow Located 7Km from Kodai Lake KodaiKanal.</p>
                  <p>
                    Escape from the hustle and hassle of your everyday routine and come away to the Valley View Stay, where a serene world awaits you.
                  </p>
                </ul>
              </Center>
            </Card>
            <Card className='footer-container-card'>
              <Center>
                <h1>
                  Information
                </h1>
              </Center>
              <Center>
                <ul>
                  <li>Karumbarai road,</li>
                  <li>Near mother Theresa University,</li>
                  <li>Attuvampatty,</li>
                  <li>KodaiKanal.</li>
                  <li>
                    <PhoneCall /> +91 98425 05161
                  </li>
                </ul>
              </Center>
            </Card>
            <Card className='footer-container-card'>
              <Center>
                <h1>
                  Quick Links
                </h1>
              </Center>
              <Center>
                <ul>
                  <li>
                    <Link to={'/'} onClick={() => window.scrollTo(0, 0)} className="nav-link">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to={'/about'} onClick={() => window.scrollTo(0, 0)} className="nav-link">
                      About
                    </Link>
                  </li>
                  <li>
                    <Link to={'/rooms'} onClick={() => window.scrollTo(0, 0)} className="nav-link">
                      Rooms
                    </Link>
                  </li>
                  <li>
                    <Link to={'/gallery'} onClick={() => window.scrollTo(0, 0)} className="nav-link">
                      Gallery
                    </Link>
                  </li>
                  <li>
                    <Link to={'/contact'} onClick={() => window.scrollTo(0, 0)} className="nav-link">
                      Contact
                    </Link>
                  </li>
                </ul>
              </Center>
            </Card>
          </div>

          {/* Dont'Miss */}


          {/* Dont'Miss End */}
        </div>
        <div className='footer-copyrights'>
          Copyright © 2023. All Rights Reserved. Designed by <a target='_blank' rel='noreferrer' href='https://weboney.com/'> Weboney</a>
        </div>
      </div>

    </div>
  )
}

export default Footer
