import React, { useEffect, useState } from 'react'
import "./Home.css"
import { Link } from "react-router-dom"

// Pages
import SwipperComponent from '../../Components/Swipper/Swipper'


// Images
import Banner1 from "../../Assets/BannerImages/Banner1.webp"
import Banner2 from "../../Assets/BannerImages/Banner2.webp"
import Banner3 from "../../Assets/BannerImages/Banner3.webp"
// ---
import Image1 from "../../Assets/Images/Image1.webp"
import Image2 from "../../Assets/Images/Image12.webp"
import Image3 from "../../Assets/Images/Image15.webp"
import Image4 from "../../Assets/Images/Image7.webp"
import Image5 from "../../Assets/Images/Image10.webp"
import Image6 from "../../Assets/Images/Image29.webp"
import ReviewImage from "../../Assets/Images/Image11.webp"
//--NearBy Images
import NearByImages1 from "../../Assets/NearByPlaces/NearByPlaces1.webp"
import NearByImages2 from "../../Assets/NearByPlaces/NearByPlaces2.webp"
import NearByImages3 from "../../Assets/NearByPlaces/NearByPlaces3.webp"
import NearByImages4 from "../../Assets/NearByPlaces/NearByPlaces4.webp"
import NearByImages5 from "../../Assets/NearByPlaces/NearByPlaces5.webp"
import NearByImages6 from "../../Assets/NearByPlaces/NearByPlaces6.webp"
import NearByImages7 from "../../Assets/NearByPlaces/NearByPlaces10.webp"

// Mantine Core
import { Input, Button, Card, Center, Rating } from '@mantine/core'

// Tabler Icons
import { Accessible, ArrowNarrowRight, Bed, BrandFacebook, BrandInstagram, Palette, ShieldCheck, Star, Ticket } from 'tabler-icons-react'


// Imags
import Trucking from "../../Assets/Truking/Trucking1.webp"
import Cloud from "../../Assets/Cloud/Cloud2.webp"
import Cloud2 from "../../Assets/Cloud/Cloud.webp"
import Cloud3 from "../../Assets/Cloud/Cloud3.webp"
import Cloud4 from "../../Assets/Cloud/Cloud4.webp"
import Cloud5 from "../../Assets/Cloud/Cloud5.webp"


// Swiper
import { SwiperSlide, Swiper } from 'swiper/react'
import { Autoplay, Navigation, Pagination } from 'swiper/modules'
import 'swiper/css';
import 'swiper/css/navigation';

// Video
import BackgroundVideo from "../../Assets/Videos/Video1.mp4"





const Home = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    const SwiperImages = [
        Banner1, Banner2, Banner3
    ]

    const activityImage = [
        Image1, Image2, Image3, Image4, Image5,Image6
    ]

    const customerReview = [
        {
            review: `We had a wonderful stay at Valley View. The environment and the atmosphere helped us to relax. We had a very good stay.`,
            rating: 5,
            name: `Dhiyanesh Siva`,
            place: 'Erode'
        },
        {
            review: `We thoroughly enjoyed our stay. The staffs were always motivated to help us. The food was excellent, the chef ensured that our pallet needs were met. The hotel ambience was lovely & very serene. We look forward to coming back. `,
            rating: 4,
            name: `Manoj`,
            place: 'Chennai'
        },
        {
            review: `Had a very relaxed stay. Great service amazing Spa. Lovely building, calming greenery, serene lake. What more could one ask for? We will be back again hopefully.`,
            rating: 4,
            name: `Senthil Vignesh`,
            place: 'Bengalore'
        },
        {
            review: `Excellent Reception, clean tidy, sweet memories. Thank you all.`,
            rating: 5,
            name: `Madhu Mathy`,
            place: 'Bengalore'
        },
    ]

    const API_KEY = '2519f3dde9ab43e3024bb828c55d43f4';
    const CITY_NAME = 'Kodaikanal';
    const [weatherData, setWeatherData] = useState(null);

    useEffect(() => {
        fetch(`https://api.openweathermap.org/data/2.5/weather?q=${CITY_NAME}&units=metric&appid=${API_KEY}`)
            .then(response => response.json())
            .then(data => setWeatherData(data))
            .catch(error => console.error('Error fetching weather data:', error));
    }, [])

    const NeabyImages = [
        NearByImages1,
        NearByImages2,
        NearByImages3,
        NearByImages4,
        NearByImages5,
        NearByImages6,
        NearByImages7,
    ]


    const customStyle = {
        "--i": "1",
    };

    const ColudImages = [
        Cloud,
        Cloud2,
        Cloud3,
        Cloud4,
        Cloud5,
    ]

    return (
        <div className='home-position-relative'>
            <div className='home-page-cloud'>

                {ColudImages.map((src, index) => (
                    <img
                        key={index}
                        src={src}
                        alt="Cloud"
                        style={{ ...customStyle, '--i': index + 1 }}
                    />
                ))}
            </div>
            <div className='home-page'>
                <div className='home-container'>

                    {/* Swiper Section */}
                    <div className='home-swipper-container'>
                        <SwipperComponent SwiperImages={SwiperImages} />
                    </div>
                    {/* Swiper Section End */}


                    {/* Content  */}
                    <div className='home-about-us-container-section'>

                        <div className='home-about-us-container'>
                            <div className='home-about-us-container-content'>
                                <div className='home-about-us-container-content-para'>
                                    <h1>
                                        Enjoy Your Stay!
                                    </h1>
                                    <p>SOME WORD ABOUT US</p>
                                </div>
                                <div className='home-about-us-container-content-para'>
                                    <h1>
                                        Valley View Bungalow Located 7Km from Kodai Lake , KodaiKanal.
                                    </h1>
                                    <p>
                                        Escape from the hustle and hassle of your everyday routine and come away to the  Valley View Stay, where a serene world awaits you. Throw off all your worries and slip into the scenic, relaxed atmosphere and be pampered and rejuvenated. Lay back and enjoy the beautiful perennial stream that caresses the landscape between the villas, cottages and tents and let yourself be taken to a paradise surrounded by The Hills.
                                    </p>
                                </div>
                                <div className='home-about-us-container-content-button'>
                                    <Button component={Link} to={'/about'} className='home-about-us-container-content-btn'>
                                        Explore More <ArrowNarrowRight />
                                    </Button>
                                </div>
                            </div>
                            <div className='home-about-us-container-grid'>
                                <div className='home-about-us-container-grid-card'>
                                    <Card
                                        shadow="sm" radius="md" withBorder
                                        className='home-about-us-container-grid-mantine-card'
                                    >
                                        <img src={Trucking} alt='Trucking' />
                                    </Card>
                                    <Card className='home-about-us-container-grid-mantine-card'>
                                        <Center>
                                            <Accessible
                                                size={68}
                                                strokeWidth={1}
                                                color={' #CF9D6C'} />
                                        </Center>
                                        <Center>
                                            <p>
                                                Ratings
                                            </p>
                                        </Center>
                                        <Center>
                                            <h1>
                                                4.8
                                            </h1>
                                        </Center>
                                        <Center>
                                            <Rating defaultValue={5} readOnly />
                                        </Center>
                                        <Center>
                                            <h5>
                                                50+ Ratings
                                            </h5>
                                        </Center>
                                    </Card>
                                </div>
                                <div className='home-about-us-container-grid-card'>
                                    <Card p={3} className='home-about-us-container-grid-card-right-mantine-card'>
                                        <div>
                                            <Bed className='home-about-us-container-grid-card-right-mantine-card-icon' strokeWidth={1} />
                                        </div>
                                        <div>
                                            <p>
                                                Compact and
                                            </p>
                                            <p>
                                                Comfortable Rooms
                                            </p>
                                        </div>
                                    </Card>
                                    <Card p={3} className='home-about-us-container-grid-card-right-mantine-card'>
                                        <div>
                                            <Ticket className='home-about-us-container-grid-card-right-mantine-card-icon' strokeWidth={1} />
                                        </div>
                                        <div>
                                            <p>
                                                Friendly Service
                                            </p>
                                            <p>
                                                and Fair Prices
                                            </p>
                                        </div>
                                    </Card>
                                    <Card p={3} className='home-about-us-container-grid-card-right-mantine-card'>
                                        <div>
                                            <Palette className='home-about-us-container-grid-card-right-mantine-card-icon' strokeWidth={1} />
                                        </div>
                                        <div>
                                            <p>
                                                Light, Air, Clean Design
                                            </p>
                                            <p>
                                                and Art Exhibition
                                            </p>
                                        </div>
                                    </Card>
                                    <Card p={3} className='home-about-us-container-grid-card-right-mantine-card'>
                                        <div>
                                            <ShieldCheck className='home-about-us-container-grid-card-right-mantine-card-icon' strokeWidth={1} />
                                        </div>
                                        <div>
                                            <p>
                                                Public Safety and
                                            </p>
                                            <p>
                                                24/7 Security
                                            </p>
                                        </div>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Content ENd */}



                    {/* Activity  */}

                    <div className='home-activity-container'>
                        <div className='home-activity-container-left'>
                            <Swiper
                                loop={true}
                                navigation={true}
                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: false,
                                }}
                                modules={[Pagination, Autoplay, Navigation]}
                                className='home-activity-carousel-swiper mySwiper'
                            >
                                {
                                    activityImage.map((value, index) => {
                                        return (
                                            <SwiperSlide key={index} className='home-activity-carousel-swiper-slide'>
                                                <img src={value} alt='Activity' />
                                            </SwiperSlide >
                                        )
                                    })
                                }
                            </Swiper>
                        </div>
                        <div className='home-activity-container-right'>
                            <div className='home-activity-container-right-content'>
                                <h1>
                                    Activities
                                </h1>
                                <p>
                                    THINGS TO DO AT THE VALLEY VIEW
                                </p>
                            </div>
                            <div className='home-activity-container-right-content'>
                                <p>
                                    Besides the explosion of colour and life that characterises our summer, the Principality cordially invites you to enjoy many other experiences and activities during this season. R&R, culture, shopping, gastronomy… A true pleasure for the senses.
                                </p>
                            </div>
                            <div className='home-about-us-container-content-button'>
                                <Button component={Link} to={'/gallery'} className='home-about-us-container-content-btn'>
                                    Explore More <ArrowNarrowRight />
                                </Button>
                            </div>
                        </div>
                    </div>

                    {/* Activity End */}


                    {/* Home Review */}

                    <div className='home-customer-review-container'>
                        <div className='home-customer-review-container-image' >
                            <img src={ReviewImage} alt='Valley-View' />
                            <div className='home-customer-review-container-image-animation'>
                                <h5>
                                    ________ TESTIMONIALS
                                </h5>
                                <h1>
                                    Client Review
                                </h1>
                                <Button component={Link} to="/contact" className='home-customer-review-container-image-animation-btn'>
                                    Contact Us
                                </Button>
                                <div className='home-customer-review-container-image-animation-award'>
                                    <div>
                                        <Star
                                            size={30}
                                            strokeWidth={1}
                                            color={'white'}
                                        />
                                    </div>
                                    AWARDED
                                    RESTAURANT
                                </div>
                            </div>
                        </div>
                        <div className='home-customer-review-container-swiper'>
                            <Swiper
                                loop={true}
                                navigation={true}
                                // autoplay={true}
                                modules={[Autoplay, Navigation]}
                                className='home-customer-review-container-swiper-js mySwipper'
                            >
                                {
                                    customerReview.map((value, index) => {
                                        return (
                                            <SwiperSlide key={index} className='home-customer-review-container-swiper-js-slide'>
                                                <div className='home-customer-review-container-swiper-js-slide-content'>
                                                    <div className='home-customer-review-container-swiper-js-slide-content-rating'>
                                                        <Rating defaultValue={value.rating} readOnly />
                                                    </div>
                                                    <div className='home-customer-review-container-swiper-js-slide-content-review'>
                                                        <p>
                                                            <span className='home-customer-review-container-swiper-js-slide-content-review-para'>
                                                                " {value.review} "
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className='home-customer-review-container-swiper-js-slide-content-name'>
                                                        <h1>
                                                            {value.name}
                                                        </h1>
                                                        <p>
                                                            {value.place}
                                                        </p>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>
                        </div>
                    </div>
                    {/* Home Review End */}




                    {/* Home NearBy Places */}
                    <div className='home-nearby-container'>
                        <video autoPlay loop muted>
                            <source src={BackgroundVideo} type='video/mp4' />
                        </video>
                        <div className='home-nearby-content'>
                            <div>
                                <h1>
                                    <span className='home-nearby-content-white'>
                                        Valley View </span>  Stay
                                </h1>
                                <p>
                                    Peak Comfort, Peak Serenity: Welcome to Our Valley View Stay"
                                </p>
                            </div>
                        </div>
                    </div>


                    {/* Weather */}

                    <div className='home-weather-container'>
                        <div className='home-weather-container-card'>
                            <h1>
                                Current Weather
                            </h1>
                            <h3>
                                Kodaikanal, Tamil Nadu
                            </h3>
                            <div className='footer-container-email-icons'>
                                <h2>
                                    {weatherData?.main?.temp !== null ? `${Math.round(weatherData?.main?.temp)}°C/°F` : "15°C/°F"}
                                </h2>
                                {weatherData?.weather[0]?.description}
                            </div>
                        </div>
                        <div className='home-weather-container-card'>
                            <h1>
                                Stay Connected
                            </h1>
                            <h3>
                                Follow us on social media channels
                            </h3>
                            <div className='footer-container-email-icons'>
                                <BrandFacebook strokeWidth={1} size={'3rem'} className='footer-container-email-icons-tabler-icons' />
                                <BrandInstagram strokeWidth={1} size={'3rem'} className='footer-container-email-icons-tabler-icons' />
                            </div>
                        </div>
                        <div className='home-weather-container-card'>
                            <h1>
                                Newsletter Sign Up
                            </h1>
                            <h3>
                                Sign up for news and special offers
                            </h3>
                            <div className='footer-container-email-input'>
                                <Input placeholder='Enter Your Email' className='footer-container-email-mantine-input' />
                                <Button className='footer-container-email-btn'>
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </div>



                    {/* Weather End */}



                    <div className='background-image'>
                        <Center style={{ display: 'flex', flexDirection: 'column' }}>
                            <h1>
                                Near By Places
                            </h1>
                            <p>
                                EXPLORE AMAZING AND BEAUTIFUL PLACES IN KODAIKANAL
                            </p>
                        </Center>
                    </div>
                    <div className='home-nearby-places'>
                        {
                            NeabyImages.map((value, index) => {
                                return (
                                    <div key={index} className='home-nearby-places-images'>
                                        <img src={value} alt='NearByImages' />
                                    </div>
                                )
                            })
                        }
                    </div>
                    {/* Home NearBy Places End */}
                </div>
            </div>
        </div >
    )
}
export default Home
