import React,{useEffect} from 'react'
import "./About.css"
import {Link} from "react-router-dom"

// Images
import Image1 from "../../Assets/Images/Image23.webp"
// ---Flex Images
import FlexImage1 from "../../Assets/Images/Image2.webp"
import FlexImage2 from "../../Assets/Images/Image11.webp"
import FlexImage3 from "../../Assets/Images/Image3.webp"
import FlexImage4 from "../../Assets/Images/Image5.webp"
// iconswebp
import Icon1 from '../../Assets/AboutIcons/Happy.webp'
import Icon2 from '../../Assets/AboutIcons/HomeStay.webp'
import Icon3 from '../../Assets/AboutIcons/Leaf.webp'
// ----Contact-Image
import ContactUS from "../../Assets/ImageConatctUs.webp"


// Tabler Icons
import { ArrowRight, Award, Star } from 'tabler-icons-react'


// Mantine
import { useMediaQuery } from '@mantine/hooks';
import { Center,Button } from "@mantine/core"


const About = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // Media Query 
  const Media500 = useMediaQuery('(max-width:500px)')



  // Array Flex Images
  const FlexImages = [FlexImage1, FlexImage2, FlexImage3, FlexImage4]

  // Choose us Flex Array of Object Data
  const chooseUs = [
    {
      icon: Icon3,
      title: `Live Amidst Nature`,
      para: `Feel and experience nature in its fullest glory to refresh yourself.`
    },
    {
      icon: Icon2,
      title: `Family Friendly`,
      para: `The calm and comfortable environment will make your family feel at home.`
    },
    {
      icon: Icon1,
      title: `Heritage Homestay`,
      para: `Experience the charm of Kerala heritage that have been preserved throughout the ages.`
    },
  ]


  return (
    <div>
      <div className='about-page'>
        <div className='about-header-title-image'>
          <h1>
            About us
          </h1>
          <p>
            Valley View
          </p>
        </div>



        {/* About Container */}

        <div className='about-container'>
          <div className='about-content'>
            <div className='about-content-para'>
              <div className='about-content-para-title'>
                <h1>
                  Welcome <span className='about-content-para-title-primary-color'> Home! </span>
                </h1>
                <p>
                  SOME WORD ABOUT US
                </p>
              </div>
              <div className='about-content-para-content'>
                <h1>
                  Valley View Bungalow is located 7km from Kodai lake , Kodaikanal, Tamil Nadu.
                </h1>
                <p>
                  Escape from the hustle and hassle of your everyday routine and come away to the Valley View Home Stay, where a serene world awaits you. Throw off all your worries and slip into the scenic, relaxed atmosphere and be pampered and rejuvenated..
                </p>
              </div>
              <div className='about-content-para-badge' >
                <ul>
                  <li>
                    <Star className='about-content-para-badge-icon' strokeWidth={1} size={Media500 ? 20 : 40} /> Consistently High Guest Satisfaction Since 2022</li>
                  <li><Award className='about-content-para-badge-icon' strokeWidth={1} size={Media500 ? 20 : 40} /> We have Best rooms and plan for your stay  </li>
                </ul>
              </div>
            </div>
            <div className='about-content-image'>
              <img src={Image1} alt='valley-view' />
            </div>
          </div>

          {/* ImagesFlex */}
          <div className='about-content-flex-images'>
            {
              FlexImages.map((value, index) => {
                return (
                  <div key={index} className='about-content-flex-img'>
                    <img src={value} alt='Valley-View' />
                  </div>
                )
              })
            }
          </div>
          {/* ImagesFlex End */}


          {/* why us */}
          <div className='about-choose-us-container'>
            <div className='about-choose-us-container-title'>
              <h1>
                Why 
                <span className='about-content-para-title-primary-color'> Choose Us </span>
              </h1>
              <p>
                TOP REASONS TO CHOOSE OUR HOMESTAY
              </p>
            </div>
            <div className='about-choose-us-container-grid'>
              {
                chooseUs.map((value, index) => {
                  return (
                    <div key={index} className='about-choose-us-container-grid-card'>
                      <Center>
                        <div className='about-choose-us-container-grid-card-icon'>
                          <img src={value.icon} alt='Valeey-view' />
                        </div>
                      </Center>
                      <Center>
                        <h1>
                          {value.title}
                        </h1>
                      </Center>
                      <Center>
                        <p>
                          {value.para}
                        </p>
                      </Center>
                    </div>
                  )
                })
              }
            </div>
          </div>


          {/* why us End */}


          {/* About Contact us */}

          <div className='about-contact-us-container'>
            <div className='about-contact-us-container-image' >
              <img src={ContactUS} alt='Valley-View' />
            </div>
            <div className='about-contact-us-container-content' >
              <h1>
                Book Your Stay Now!
              </h1>
              <p>
                Call us At
              </p>
              <p>
                +91 98425 05161
              </p>
              <Button component={Link} to={'/contact'} className='about-contact-us-container-content-button'>
                Contact Us &nbsp; <ArrowRight/>
              </Button>
            </div>
          </div>


          {/* About Contact us End */}
        </div>

        {/* About Container End */}

      </div>
    </div>
  )
}

export default About
