import React from 'react'
import "./Swipper.css"

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


// import required modules
import { Autoplay, Pagination } from 'swiper/modules';

const SwipperComponent = ({ SwiperImages }) => {



  return (
    <div>
      <div className='swipper-page'>
        <div className='swipper-container'>
          <Swiper
            pagination={{
              clickable: true,
            }}
            loop={true}
            autoplay={{
              delay: 6000,
              disableOnInteraction: false,
            }}
            modules={[Pagination, Autoplay]}
            className='swipper-react-swipper mySwiper'>
            {
              SwiperImages.map((value, index) => {
                return (
                  <SwiperSlide key={index} className='swipper-react-swipper-slide'>
                    {
                      index === 0 ? (
                        <div className='swipper-react-swipper-slide-content swiper-animation'>
                          <h1>
                            Best Choice For Family
                          </h1>
                          <h1>
                            A Perfect holiday
                          </h1>
                          <h1>
                            Destination
                          </h1>
                        </div>
                      ) : index === 1 ? (
                        <div className='swipper-react-swipper-slide-content swiper-animation2'>
                          <h1>
                            Best Choice For Family
                          </h1>
                          <h1>
                            Stay with us, Feel
                          </h1>
                          <h1>
                            At Home
                          </h1>
                        </div>
                      ) : index === 2 ? (
                        <div className='swipper-react-swipper-slide-content swiper-animation3'>
                          <h1>
                            Best Choice For Family
                          </h1>
                          <h1>
                            Come as a guest, Leave
                          </h1>
                          <h1>
                            as a friend
                          </h1>
                        </div>
                      ) : ''
                    }

                    <img src={value} alt='Banner' />
                  </SwiperSlide>
                )
              })
            }
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default SwipperComponent
