import React from 'react'
import { Switch, Route } from "react-router-dom"


// Pages
import Header from './Layouts/Header/Header'
import Home from './Pages/Home/Home';
import About from './Pages/About/About';
import Rooms from './Pages/Rooms/Rooms';
import NavBar from './Layouts/NavBar/NavBar';
import Gallery from './Pages/Gallery/Gallery';
import Contact from './Pages/Contact/Contact';
import Footer from './Layouts/Footer/Footer';

// Mantine Core
import { useDisclosure } from '@mantine/hooks';
import { useMediaQuery } from '@mantine/hooks';

const Router = () => {
    // Menu Icon
    const [opened, { toggle }] = useDisclosure(false);

    // Media Query 
    const Media768px = useMediaQuery('(max-width:768px)')
    return (
        <div>
            <div className={`${Media768px ? 'router-header' : " "}`} >
                <Header opened={opened} toggle={toggle} />
            </div>
            <div className={`${Media768px ? 'drop-down-navbar' : "router-header"}`}>
                <div className={`header-page-nav-bar ${opened ? 'display-block' : ''}`} >
                    <NavBar opened={opened} toggle={toggle} />
                </div>
            </div>
            <div className='router-home'>
                <Switch>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route path="/about">
                        <About />
                    </Route>
                    <Route path="/rooms">
                        <Rooms />
                    </Route>
                    <Route path="/gallery">
                        <Gallery />
                    </Route>
                    <Route path="/contact">
                        <Contact />
                    </Route>
                </Switch>
            </div>
            <div className='router-footer'>
                <Footer />
            </div>
        </div >
    )
}

export default Router
