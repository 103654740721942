import React from 'react'
import "./Header.css"

// Image
import BrandLogo from "../../Assets/Logo.png"

// Tabler Icon
import { Home, Mail, PhoneCall } from 'tabler-icons-react'


// Mantine Core
import { Burger, Button } from '@mantine/core'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const Header = ({ opened, toggle }) => {


    // Menu Bar 
    const label = opened ? 'Close navigation' : 'Open navigation';
    return (
        <div>
            <div className='header-page'>
                <div className='header-container'>
                    <div className='header-container-content'>
                        <div className='header-container-div'>
                            <div className='header-container-div-title'>
                                <Home
                                    size={30}
                                    strokeWidth={1}
                                    color={'#41566B'}
                                />
                            </div>
                            <div className='header-container-div-text'>
                                <p>
                                    Karumbarai road,
                                </p>
                                <p>
                                    Near mother Theresa University,
                                </p>
                                <p>
                                    Attuvampatty, KodaiKanal.
                                </p>
                            </div>
                        </div>
                        {/* <div className='header-container-div'>
                            <div className='header-container-div-title'>
                                <Mail
                                    size={30}
                                    strokeWidth={1}
                                    color={'#41566B'}
                                />
                            </div>
                            <div className='header-container-div-text'>
                                <p>
                                    contact@valleyviewstay.com
                                </p>
                            </div>
                        </div> */}
                    </div>
                    <div className='header-container-div'>
                        <Link to="/" onClick={() => window.scrollTo(0, 0)}>
                            <div className='header-container-div-logo'>
                                <img src={BrandLogo} alt='Valley_View' />
                            </div>
                        </Link>
                    </div>
                    <div className='header-container-content'>
                        <div className='header-container-div'>
                            <div className='header-container-div-title'>
                                <PhoneCall
                                    size={30}
                                    strokeWidth={1}
                                    color={'#41566B'}
                                />
                            </div>
                            <div className='header-container-div-text'>
                                <p className='header-container-div-text-number'>
                                    +91 98425 05161
                                </p>
                            </div>
                        </div>
                        <div className='header-container-div'>
                            <div >
                                <Button className='header-container-div-btn'>
                                    <a href='+919842505161' className="nav-link">
                                        Book Your Stay
                                    </a>
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className='header-container-menu-icon'>
                        <Burger size={25} opened={opened} onClick={toggle} aria-label={label} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header
