import React from 'react'
import ReactDOM from 'react-dom'

// Router
import { HashRouter } from 'react-router-dom/cjs/react-router-dom.min'

// Pages
import App from './App'

// Mantine Core
import { MantineProvider } from '@mantine/core'
import { ModalsProvider } from '@mantine/modals'
import { Notifications } from '@mantine/notifications'


ReactDOM.render(
    <React.StrictMode>
        <HashRouter>
            <MantineProvider withGlobalStyles withCSSVariables>
                <ModalsProvider>
                    <Notifications
                        zIndex={10999}
                        position="top-right"
                        autoClose="1500ms"
                    />
                    <App />
                </ModalsProvider>
            </MantineProvider>
        </HashRouter>
    </React.StrictMode>,
    document.getElementById('root')
)